import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { APPROVAL_HEAD } from '../../../Constants/AppConstants';
import { formatUtcToLocal } from '../../Common/commonutility';
import { withNamespaces } from 'react-i18next';
import './Approval.scss';

class EmployeeDetailsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remark: this.props.remark || '',
            error: ""
        };
    }

    handleApproval = (status) => {
        const { remark } = this.state;
        const { selectedEmployee } = this.props;
        if (!remark.trim()) {

            this.setState({ error: this.props.i18n.t('errorMessages.remarkRequired') });
        } else {
            this.setState({ error: '' });
            this.props.editStatus(selectedEmployee.id, status, remark);
        }

    };

    handleRemarkChange = (event) => {
        this.setState({ remark: event.target.value });
    };

    render() {
        const { selectedEmpList, selectedEmployee, closeModal, i18n } = this.props;
        const { remark, error } = this.state;

        return (
            <div className="employee-details-modal">

                <div className="employee-modal-content">
                    <button className="close-btn" onClick={closeModal}>×</button>
                    <div className="viewmodal-body">
                        <img
                            src={`data:image/jpeg;base64,${selectedEmployee.empImage}`}
                            alt="Employee"
                            className="modal-image"
                        />
                        <div className="viewmodal-details">
                            <div>
                                <p>
                                    {i18n.t('approvals.name')}
                                    <span className="val">{selectedEmpList.fullNameEn}</span>
                                </p>
                                <p>
                                    {i18n.t('approvals.id')}
                                    <span className="val">{selectedEmpList.empId}</span>
                                </p>
                                <p>
                                    {i18n.t('approvals.dept')}
                                    <span className="val">{selectedEmpList.deptEn}</span>
                                </p>
                                <p>
                                    {i18n.t('approvals.designation')}
                                    <span className="val">{selectedEmpList.jobEn}</span>
                                </p>
                                {selectedEmployee.approvalRemarks && (
                                    <>
                                        <p>
                                            {selectedEmployee.approvalStatus === APPROVAL_HEAD[1].activeTab
                                                ? i18n.t('approvals.updatedby')
                                                : selectedEmployee.approvalStatus === APPROVAL_HEAD[2].activeTab
                                                    ? i18n.t('approvals.rejectedby')
                                                    : ''}
                                            <span className="val"> {selectedEmployee.approverName}</span>
                                        </p>
                                        <p>
                                            {selectedEmployee.approvalStatus === APPROVAL_HEAD[1].activeTab
                                                ? i18n.t('approvals.updatedat')
                                                : selectedEmployee.approvalStatus === APPROVAL_HEAD[2].activeTab
                                                    ? i18n.t('approvals.rejectedat')
                                                    : ''}
                                            <span className="val">
                                                {formatUtcToLocal(selectedEmployee.updatedAt, 'MMM dd, yyyy HH:mm:ss')}
                                            </span>
                                        </p>
                                        <p>
                                            {i18n.t('approvals.remark')}
                                            <span className="val">{selectedEmployee.approvalRemarks}</span>
                                        </p>
                                    </>
                                )}
                            </div>

                            {selectedEmployee.approvalStatus === APPROVAL_HEAD[0].activeTab && (
                                <div className="remark-formgroup">
                                    <div className="form-group">
                                        <textarea
                                            id="remark"
                                            className="remark-input-box"
                                            value={remark}
                                            onChange={this.handleRemarkChange}
                                            rows="4"
                                            placeholder={i18n.t('approvals.enterRemarkplace')}
                                        />
                                        {error && <span className="error-text">{error}</span>}
                                    </div>
                                    <div className="viewmodal-actions">
                                        <button
                                            type="button"
                                            className="btn-approval approve-btn"
                                            onClick={() => this.handleApproval('Approved')}
                                        >
                                            {i18n.t('modal.buttons.approve')}
                                        </button>
                                        <button
                                            type="button"
                                            className="btn-approval deny-btn"
                                            onClick={() => this.handleApproval('Rejected')}
                                        >
                                            {i18n.t('modal.buttons.reject')}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EmployeeDetailsModal.propTypes = {
    selectedEmpList: PropTypes.object.isRequired,
    selectedEmployee: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    editStatus: PropTypes.func.isRequired,
    remark: PropTypes.string,
    i18n: PropTypes.object.isRequired,
};

export default withNamespaces()(EmployeeDetailsModal);
