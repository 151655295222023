import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import MainApp from '../MainApp/MainApp';
import Footer from '../Footer/Footer';
// constants
import { LANGUAGE, DIRECTION, ROUTE, DEFAULT_ROUTE } from '../../Constants/AppConstants';
// hoc
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';
import { withCommunication } from '../../HOC/Communication';
// css
import './App.scss';
// storage
import Storage from '../../Storage';

function PrivateRoute({ component: Component, ...rest }) {
    const auth = Storage.get(Storage.keys.AUTH);
    const userDisplayName = (auth) ? auth.userDisplayName : '';
    return (
        <Route
            {...rest}
            render={
                props =>
                    userDisplayName !== ''
                        ? (
                            <Component {...props} />
                        )
                        : (
                            <Redirect to={ROUTE.LOGIN.PATH} />
                        )
            }
        />
    );
}

function App(props) {
    const direction = (props.i18n.language === LANGUAGE.AR) ? DIRECTION.RTL : DIRECTION.LTR;
    return (
        <div className="app-container" style={{ direction: direction }}>
            <div className="app">
                <Switch>
                    <Redirect exact from="/" to={DEFAULT_ROUTE} />
                    {
                        Object.keys(ROUTE).map((key, index) => {
                            return (
                                (!ROUTE[key].PROTECTED)
                                    ? (
                                        <Route key={index} path={ROUTE[key].PATH} component={ROUTE[key].COMPONENT} />
                                    )
                                    : null
                            );
                        })
                    }
                    <PrivateRoute component={MainApp} />
                </Switch>
            </div>
            <Footer version={props.version} />
        </div>
    );
}

export default compose(
    withCommunication,
    withNamespaces(),
)(App);