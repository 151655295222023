export function errorLookup(error) {
    const errorObject = {
        isError: true,
        code: (error) ? error.errCode : '',
        message: 'errorMessages.somethingWentWrong',
    }
    if (error && error.errCode) {
        switch (error.errCode) {
            // http
            case 400: {
                /*not needed bcz message has it already message = 'errorMessages.somethingWentWrong'; */
                break;
            }
            // common
            case 11: {
                /*not needed bcz message has it already message = 'errorMessages.somethingWentWrong'; */
                break;
            }
            case 6002: errorObject.message = 'errorMessages.invalidArguments'; break;
            case 6005: errorObject.message = 'errorMessages.dataNotFound'; break;
            // clg and dept
            case 6003: errorObject.message = 'errorMessages.deptNotPresent'; break;
            // settings
            case 6006: {
                /*not needed bcz message has it already message = 'errorMessages.somethingWentWrong'; */
                break;
            }
            case 6004: errorObject.message = 'errorMessages.linkNotPresent'; break;
            // campus
            case 6012: errorObject.message = 'errorMessages.campusNotFound'; break;
            case 6017: errorObject.message = 'errorMessages.duplicateCampus'; break;
            // building
            case 6007: errorObject.message = 'errorMessages.buildingNotFound'; break;
            case 6013: errorObject.message = 'errorMessages.deleteNotPermited'; break;
            case 6014: errorObject.message = 'errorMessages.duplicateBuilding'; break;
            // floor
            case 6010: errorObject.message = 'errorMessages.floorNotFound'; break;
            case 6011: errorObject.message = 'errorMessages.invalidFloorId'; break;
            case 6015: errorObject.message = 'errorMessages.duplicateFloor'; break;
            // location
            case 6009: errorObject.message = 'errorMessages.locationNotFound'; break;
            case 6016: errorObject.message = 'errorMessages.duplicateLocation'; break;
            // login
            case 5007: errorObject.message = 'errorMessages.invalidCredentials'; break;

            case 6019: errorObject.message = 'errorMessages.latitudeError'; break;
            case 6020: errorObject.message = 'errorMessages.zonenameError'; break;
            case 6021: errorObject.message = 'errorMessages.zonenotfound'; break;

            case 6022: errorObject.message = 'errorMessages.approvalinvalid'; break;
            case 6023: errorObject.message = 'errorMessages.approvalnotfound'; break;
            case 6024: errorObject.message = 'errorMessages.employeenotfound'; break;

            // unknown
            case 5000: errorObject.message = 'errorMessages.applicationError'; break;
            case 5002: errorObject.message = 'errorMessages.unauthorizedUser'; break;
            // ******
            default: break;
        }
    }

    return errorObject;
}