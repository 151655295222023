import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Approval.scss'; 
import EmployeeCard from '../../Common/EmployeeList/EmployeeList'; 
import { MODAL_SIZE, withModal } from '../../../HOC/Modal';
import Pagination from '../../Common/Pagination/Pagination';
import { APPROVAL_HEAD } from '../../../Constants/AppConstants';
import { withNamespaces } from 'react-i18next';
import { URLS } from '../../../HOC/Communication/ApiUrls';
import { axiosInstance } from '../../../HOC/Communication';
import EmployeeDetailsModal from './EmployeeDetails';



class ApprovalScreen extends Component {

  static propTypes = {
    list: PropTypes.array,
    getEmployeeById: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    i18n: PropTypes.object.isRequired,
    activeTab: PropTypes.string.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      showViewModal: false,
      remark: '',
      selectedEmpList: {},
      selectedEmployee: null
    };
    this.axios = axiosInstance;
  }

  state = {


  };


  toggleModal = (employee = null) => {
    if (employee) {
      this.getEmployeeById(employee.empId,employee);

    }

    this.setState((prevState) => ({
      showViewModal: !prevState.showViewModal,
      selectedEmployee: employee || null, // Set employee when opening the modal, null when closing
      remark:null
    }));
  };

  getEmployeeById = (id,employees) => {
    this.axios.get(URLS.GET_EMPLOYEE + id)
      .then(res => {
        this.props.closeModal();
        if (!res.isError) {
          const selectedEmpList = res.data.data
          this.setState({ selectedEmpList });
          this.props.showModal(
            <EmployeeDetailsModal
            selectedEmpList= {selectedEmpList}
            selectedEmployee={employees}
            editStatus={this.props.editStatus}
            closeModal={this.props.closeModal}

            />,
            MODAL_SIZE.LG
          );
        }
       
      });
  }

  handlePageChange = (page) => {
    this.props.handlePageChange(page)

  };

  // handleApproval = (status) => {
  //   const { remark } = this.state;
  //   const { selectedEmployee } = this.state;
  //   this.props.editStatus(selectedEmployee.id, status, remark)
  // }

  renderTabContent = (tabId) => {
    const { list } = this.props;

    if (!Array.isArray(list) || list.length === 0) {
      return null;
    }



    return (
      <div className={`tab-pane ${this.props.activeTab === tabId ? 'active' : ''}`} id={`tab-${tabId}`}>
        <div className="row">
          {list.map((employee, index) => (
            <div className="col-12" key={index}>
              <EmployeeCard
                employee={employee}
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
                editStatus={this.props.editStatus}
                toggleModal={() => this.toggleModal(employee)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {

    const { pageInfo } = this.props;

    return (
      <div className="approval-screen">
        <div className="tab-content">
          {this.renderTabContent(APPROVAL_HEAD[0].activeTab)}
          {this.renderTabContent(APPROVAL_HEAD[1].activeTab)}
          {this.renderTabContent(APPROVAL_HEAD[2].activeTab)}
        </div>
        {/* Render pagination controls */}
        <Pagination
          currentPage={pageInfo ?pageInfo.pageNumber:1}
          totalItems={pageInfo ? pageInfo.totalPages : 0} 
          onPageChange={this.handlePageChange}
        />
      </div>
      
    );
  }
}

export default withNamespaces()(withModal(ApprovalScreen));
