import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Input,
} from 'reactstrap';
import CustomList from '../Common/CustomList/CustomList';
import PopupModal from '../Common/PopupModal/PopupModal';
// css
import Styles from './Settings.scss';

// hoc
import { withNamespaces } from 'react-i18next';
// assets
import Edit from '../../Assets/Edit';
import Cancel from '../../Assets/Cancel';
import Ok from '../../Assets/Ok';
// constants
import { PARSING_KEYS } from '../../Constants/AppConstants';
import { MODAL_SIZE } from '../../HOC/Modal';

class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editingOn: '',
            otherSettings: null,
            feedbacksettings: null,
            eventsettings: null,
            dataupdate:null,
            reportlostitem:null,
            securityServices:null
        }
    }

    static propTypes = {
        showModal: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        metadata: PropTypes.array.isRequired,
        otherSettings: PropTypes.array,
        feedbacksettings: PropTypes.array,
        eventsettings: PropTypes.array,
        dataupdate:PropTypes.array,
        reportlostitem:PropTypes.array,
        securityServices:PropTypes.array,

        getOtherSettings: PropTypes.func.isRequired,
        getfeedbackSettings: PropTypes.func.isRequired,
        geteventSettings: PropTypes.func.isRequired,
        getdataUpdate: PropTypes.func.isRequired,
        getreportlostItem: PropTypes.func.isRequired,
        getSecurityServices: PropTypes.func.isRequired,

        getOtherSettingById: PropTypes.func.isRequired,
        getFeedbackSettingById: PropTypes.func.isRequired,
        getEventSettingById: PropTypes.func.isRequired,
        getDataUpdateById: PropTypes.func.isRequired,
        getReportLostItemById: PropTypes.func.isRequired,
        getSecurityServicesById: PropTypes.func.isRequired,

        putOtherSettingById: PropTypes.func.isRequired,
        putFeedbackSettingById: PropTypes.func.isRequired,
        putEventSettingById: PropTypes.func.isRequired,
        putDataUpdateById: PropTypes.func.isRequired,
        putReportLostItemById: PropTypes.func.isRequired,
        putSecurityServicesById: PropTypes.func.isRequired,

    }

    static getDerivedStateFromProps(props, state) {
        return ({
            editingOn: state.editingOn,
            otherSettings: (state.editingOn) ? state.otherSettings : JSON.parse(JSON.stringify(props.otherSettings)),
            feedbacksettings: (state.editingOn) ? state.feedbacksettings : JSON.parse(JSON.stringify(props.feedbacksettings)),
            eventsettings: (state.editingOn) ? state.eventsettings : JSON.parse(JSON.stringify(props.eventsettings)),
            dataupdate: (state.editingOn) ? state.dataupdate : JSON.parse(JSON.stringify(props.dataupdate)),
            reportlostitem: (state.editingOn) ? state.reportlostitem : JSON.parse(JSON.stringify(props.reportlostitem)),
            securityServices: (state.editingOn) ? state.securityServices : JSON.parse(JSON.stringify(props.securityServices)),
        });
    }

    renderList = (list) => {
        return (
            <CustomList
                list={list}
                keys={this.props.metadata}
                showModal={this.props.showModal}
                closeModal={this.props.closeModal}
            />
        )
    }


    showSettingsErrorModal = (message, id, oldValue) => {
        const { otherSettings } = this.state;
        const { SETTINGS } = PARSING_KEYS;
        const index = otherSettings.findIndex(element => element[SETTINGS.ID] === id)
        otherSettings[index][SETTINGS.VALUE] = oldValue;
        this.setState({
            otherSettings,
        });
        this.props.showModal(
            <PopupModal
                isError
                message={message}
                onOkClick={this.props.closeModal}
            />
            , MODAL_SIZE.SM
        )
    }

    showFeedbackSettingsErrorModal = (message, id, oldValue) => {
        const {feedbacksettings} = this.state;
        const { SETTINGS } = PARSING_KEYS;
        const index = feedbacksettings.findIndex(element => element[SETTINGS.ID] === id)
        feedbacksettings[index][SETTINGS.VALUE] = oldValue;
        this.setState({
            feedbacksettings,
        });
        this.props.showModal(
            <PopupModal
                isError
                message={message}
                onOkClick={this.props.closeModal}
            />
            , MODAL_SIZE.SM
        )
    }

    showEventSettingsErrorModal = (message, id, oldValue) => {
        const { eventsettings } = this.state;
        const { SETTINGS } = PARSING_KEYS;
        const index = eventsettings.findIndex(element => element[SETTINGS.ID] === id)
        eventsettings[index][SETTINGS.VALUE] = oldValue;
        this.setState({
            eventsettings,
        });
        this.props.showModal(
            <PopupModal
                isError
                message={message}
                onOkClick={this.props.closeModal}
            />
            , MODAL_SIZE.SM
        )
    }

    showDataUpdateErrorModal = (message, id, oldValue) => {
        const { dataupdate } = this.state;
        const { SETTINGS } = PARSING_KEYS;
        const index = dataupdate.findIndex(element => element[SETTINGS.ID] === id)
        dataupdate[index][SETTINGS.VALUE] = oldValue;
        this.setState({
            dataupdate,
        });
        this.props.showModal(
            <PopupModal
                isError
                message={message}
                onOkClick={this.props.closeModal}
            />
            , MODAL_SIZE.SM
        )
    }

    showReportLostItemErrorModal = (message, id, oldValue) => {
        const { reportlostitem } = this.state;
        const { SETTINGS } = PARSING_KEYS;
        const index = reportlostitem.findIndex(element => element[SETTINGS.ID] === id)
        reportlostitem[index][SETTINGS.VALUE] = oldValue;
        this.setState({
            reportlostitem,
        });
        this.props.showModal(
            <PopupModal
                isError
                message={message}
                onOkClick={this.props.closeModal}
            />
            , MODAL_SIZE.SM
        )
    }

    showSecurityServicesErrorModal = (message, id, oldValue) => {
        const { securityServices } = this.state;
        const { SETTINGS } = PARSING_KEYS;
        const index = securityServices.findIndex(element => element[SETTINGS.ID] === id)
        securityServices[index][SETTINGS.VALUE] = oldValue;
        this.setState({
            securityServices,
        });
        this.props.showModal(
            <PopupModal
                isError
                message={message}
                onOkClick={this.props.closeModal}
            />
            , MODAL_SIZE.SM
        )
    }

    onOkClick = (data) => {
        const { SETTINGS } = PARSING_KEYS;
        if (data[SETTINGS.VALUE] !== '') {
            let requestBody = this.props.otherSettings.find(element => element[SETTINGS.ID] === data[SETTINGS.ID]);
            if (requestBody.COMPARE_WITH) {
                const compareValue = parseInt(this.props.otherSettings.find(element => element[SETTINGS.ID] === requestBody.COMPARE_WITH)[SETTINGS.VALUE]);
                const oldValue = parseInt(requestBody[SETTINGS.VALUE]);
                const newValue = parseInt(data[SETTINGS.VALUE]);
                if (!requestBody.COMPARE_FUNCTION(newValue, compareValue)) {
                    this.showSettingsErrorModal(`settings.${requestBody[SETTINGS.ID]}.errorMessage`, requestBody[SETTINGS.ID], oldValue);
                    return;
                }
            }
            requestBody[SETTINGS.VALUE] = data[SETTINGS.VALUE];
            this.props.putOtherSettingById(requestBody);
            this.setState({ editingOn: '' });
        }
        else {
            this.setState({
                editingOn: '',
                otherSettings: this.props.otherSettings,
            });
        }
    }


    onOkClickfeedback = (data) => {
        const { SETTINGS } = PARSING_KEYS;
        if (data[SETTINGS.VALUE] !== '') {
            let requestBody = this.props.feedbacksettings.find(element => element[SETTINGS.ID] === data[SETTINGS.ID]);
            if (requestBody.COMPARE_WITH) {
                const compareValue = parseInt(this.props.feedbacksettings.find(element => element[SETTINGS.ID] === requestBody.COMPARE_WITH)[SETTINGS.VALUE]);
                const oldValue = parseInt(requestBody[SETTINGS.VALUE]);
                const newValue = parseInt(data[SETTINGS.VALUE]);
                if (!requestBody.COMPARE_FUNCTION(newValue, compareValue)) {
                    this.showFeedbackSettingsErrorModal(`settings.${requestBody[SETTINGS.ID]}.errorMessage`, requestBody[SETTINGS.ID], oldValue);
                    return;
                }
            }
            requestBody[SETTINGS.VALUE] = data[SETTINGS.VALUE];
            this.props.putFeedbackSettingById(requestBody);
            this.setState({ editingOn: '' });
        }
        else {
            this.setState({
                editingOn: '',
                feedbacksettings: this.props.feedbacksettings,
            });
        }
    }


    onOkClickEvent = (data) => {
        const { SETTINGS } = PARSING_KEYS;
        if (data[SETTINGS.VALUE] !== '') {
            let requestBody = this.props.eventsettings.find(element => element[SETTINGS.ID] === data[SETTINGS.ID]);
            if (requestBody.COMPARE_WITH) {
                const compareValue = parseInt(this.props.eventsettings.find(element => element[SETTINGS.ID] === requestBody.COMPARE_WITH)[SETTINGS.VALUE]);
                const oldValue = parseInt(requestBody[SETTINGS.VALUE]);
                const newValue = parseInt(data[SETTINGS.VALUE]);
                if (!requestBody.COMPARE_FUNCTION(newValue, compareValue)) {
                    this.showEventSettingsErrorModal(`settings.${requestBody[SETTINGS.ID]}.errorMessage`, requestBody[SETTINGS.ID], oldValue);
                    return;
                }
            }
            requestBody[SETTINGS.VALUE] = data[SETTINGS.VALUE];
            this.props.putEventSettingById(requestBody);
            this.setState({ editingOn: '' });
        }
        else {
            this.setState({
                editingOn: '',
                eventsettings: this.props.eventsettings,
            });
        }
    }

    onOkClickDataUpdate = (data) => {
        const { SETTINGS } = PARSING_KEYS;
        if (data[SETTINGS.VALUE] !== '') {
            let requestBody = this.props.dataupdate.find(element => element[SETTINGS.ID] === data[SETTINGS.ID]);
            if (requestBody.COMPARE_WITH) {
                const compareValue = parseInt(this.props.dataupdate.find(element => element[SETTINGS.ID] === requestBody.COMPARE_WITH)[SETTINGS.VALUE]);
                const oldValue = parseInt(requestBody[SETTINGS.VALUE]);
                const newValue = parseInt(data[SETTINGS.VALUE]);
                if (!requestBody.COMPARE_FUNCTION(newValue, compareValue)) {
                    this.showDataUpdateErrorModal(`settings.${requestBody[SETTINGS.ID]}.errorMessage`, requestBody[SETTINGS.ID], oldValue);
                    return;
                }
            }
            requestBody[SETTINGS.VALUE] = data[SETTINGS.VALUE];
            this.props.putDataUpdateById(requestBody);
            this.setState({ editingOn: '' });
        }
        else {
            this.setState({
                editingOn: '',
                dataupdate: this.props.dataupdate,
            });
        }
    }

    onOkClickReportLostItem = (data) => {
        const { SETTINGS } = PARSING_KEYS;
    
        if ( (data[SETTINGS.ID]!== 'LOST_ITEM_PHONE_NUMBER'&& data[SETTINGS.VALUE] !== '') || (data[SETTINGS.ID]=== 'LOST_ITEM_PHONE_NUMBER'&& data[SETTINGS.VALUE].length === 11)) {
            let requestBody = this.props.reportlostitem.find(element => element[SETTINGS.ID] === data[SETTINGS.ID]);
            if (requestBody.COMPARE_WITH) {
                const compareValue = parseInt(this.props.reportlostitem.find(element => element[SETTINGS.ID] === requestBody.COMPARE_WITH)[SETTINGS.VALUE]);
                const oldValue = parseInt(requestBody[SETTINGS.VALUE]);
                const newValue = parseInt(data[SETTINGS.VALUE]);
                if (!requestBody.COMPARE_FUNCTION(newValue, compareValue)) {
                    this.showReportLostItemErrorModal(`settings.${requestBody[SETTINGS.ID]}.errorMessage`, requestBody[SETTINGS.ID], oldValue);
                    return;
                }
            }
            requestBody[SETTINGS.VALUE] = data[SETTINGS.VALUE];
            this.props.putReportLostItemById(requestBody);
            this.setState({ editingOn: '' });
        }
        else {
            this.setState({
                editingOn: '',
                reportlostitem: this.props.reportlostitem,
            });
        }
    }

    onOkClickSecurityServices = (data) => {
        const { SETTINGS } = PARSING_KEYS;
        if (data[SETTINGS.VALUE].length === 11) {
            let requestBody = this.props.securityServices.find(element => element[SETTINGS.ID] === data[SETTINGS.ID]);
            if (requestBody.COMPARE_WITH) {
                const compareValue = parseInt(this.props.securityServices.find(element => element[SETTINGS.ID] === requestBody.COMPARE_WITH)[SETTINGS.VALUE]);
                const oldValue = parseInt(requestBody[SETTINGS.VALUE]);
                const newValue = parseInt(data[SETTINGS.VALUE]);
                if (!requestBody.COMPARE_FUNCTION(newValue, compareValue)) {
                    this.showSecurityServicesErrorModal(`settings.${requestBody[SETTINGS.ID]}.errorMessage`, requestBody[SETTINGS.ID], oldValue);
                    return;
                }
            }
            requestBody[SETTINGS.VALUE] = data[SETTINGS.VALUE];
            this.props.putSecurityServicesById(requestBody);
            this.setState({ editingOn: '' });
        }
        else {
            this.setState({
                editingOn: '',
                securityServices: this.props.securityServices,
            });
        }
    }

    validateSettings = (index, value) => {
        let { otherSettings } = this.state;
        otherSettings = JSON.parse(JSON.stringify(otherSettings))
        const regex = new RegExp(otherSettings[index].REGEX);
        if (regex.test(value)) {
            otherSettings[index][PARSING_KEYS.SETTINGS.VALUE] = value;
            this.setState({ otherSettings });
        }
    }

    validateFeedbackSettings = (index, value) => {
        let { feedbacksettings } = this.state;
        feedbacksettings = JSON.parse(JSON.stringify(feedbacksettings))
        const regex = new RegExp(feedbacksettings[index].REGEX);
        if (regex.test(value)) {
            feedbacksettings[index][PARSING_KEYS.SETTINGS.VALUE] = value;
            this.setState({ feedbacksettings });
        }
    }

    validateEventSettings = (index, value) => {
        let { eventsettings } = this.state;
        eventsettings = JSON.parse(JSON.stringify(eventsettings))
        const regex = new RegExp(eventsettings[index].REGEX);
        if (regex.test(value)) {
            eventsettings[index][PARSING_KEYS.SETTINGS.VALUE] = value;
            this.setState({ eventsettings });
        }
    }

    validateDataUpdate= (index, value) => {
        let { dataupdate } = this.state;
        dataupdate = JSON.parse(JSON.stringify(dataupdate))
        const regex = new RegExp(dataupdate[index].REGEX);
        if (regex.test(value)) {
            dataupdate[index][PARSING_KEYS.SETTINGS.VALUE] = value;
            this.setState({ dataupdate });
        }
    }

    validateReportLostItem= (index, value) => {
        let { reportlostitem } = this.state;
        reportlostitem = JSON.parse(JSON.stringify(reportlostitem))
        console.log('✌️regex --->', reportlostitem[index].REGEX);
        const regex = new RegExp(reportlostitem[index].REGEX);
         console.log('✌️regex --->', regex);
         console.log('✌️value --->', regex.test(value));
        if (regex.test(value)) {

            reportlostitem[index][PARSING_KEYS.SETTINGS.VALUE] = value;
            this.setState({ reportlostitem });
        }
    }

    validateServiceSecurity= (index, value) => {
        let { securityServices } = this.state;
        securityServices = JSON.parse(JSON.stringify(securityServices))
        const regex = new RegExp(securityServices[index].REGEX);
        if (regex.test(value)) {
            securityServices[index][PARSING_KEYS.SETTINGS.VALUE] = value;
            this.setState({ securityServices });
        }
    }


    renderSettings = (list, startingIndex = 0) => {
        const { SETTINGS } = PARSING_KEYS;
        return (
            <div className="other-settings-panel">
                {
                    (list.length === 0)
                        ? (
                            <div className="text-center">
                                {this.props.i18n.t('common.noDataAvailable')}
                            </div>
                        )
                        : (
                            list.map((element, index) => {
                                return (
                                    <div key={element[SETTINGS.ID]} className="settings-element">
                                        <div className="input-label">
                                            <div>
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.text`)}
                                            </div>
                                            <div className="hint-text">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.hint-text`)}
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-value">
                                                {
                                                    this.state.editingOn === element[SETTINGS.ID]
                                                        ? <Input
                                                            bsSize="sm"
                                                            value={element[SETTINGS.VALUE]}
                                                            onChange={(event) => this.validateSettings(index + startingIndex, event.target.value)}
                                                        />
                                                        : element[SETTINGS.VALUE]
                                                }
                                            </div>
                                            <div className="input-unit">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.units`)}
                                            </div>
                                        </div>
                                        <div className="img-container">
                                            {
                                                this.state.editingOn === element[SETTINGS.ID]
                                                    ? (
                                                        <div>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => this.onOkClick(element)}
                                                            >
                                                                <Ok
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    this.props.getOtherSettings();
                                                                    this.setState({ editingOn: '' });
                                                                }}
                                                            >
                                                                <Cancel
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                    : (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                this.props.getOtherSettingById(
                                                                    element[SETTINGS.ID],
                                                                    (otherSettings) => {
                                                                        this.setState({
                                                                            editingOn: element[SETTINGS.ID],
                                                                            otherSettings,
                                                                        });
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <Edit
                                                                fillColor={Styles.primaryColor}
                                                            />
                                                        </span>
                                                    )
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        )
                }
            </div>
        );
    }

    renderFeedbackSettings = (list, startingIndex = 0) => {
        const { SETTINGS } = PARSING_KEYS;
        return (
            <div className="other-settings-panel">
                {
                    (list.length === 0)
                        ? (
                            <div className="text-center">
                                {this.props.i18n.t('common.noDataAvailable')}
                            </div>
                        )
                        : (
                            list.map((element, index) => {
                                return (
                                    <div key={element[SETTINGS.ID]} className="settings-element">
                                        <div className="input-label">
                                            <div>
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.text`)}
                                            </div>
                                            <div className="hint-text">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.hint-text`)}
                                            </div>
                                        
                                        <div className="input-container">
                                            <div className="input-value">
                                                {
                                                    this.state.editingOn === element[SETTINGS.ID]
                                                        ? <Input
                                                            bsSize="sm"
                                                            value={element[SETTINGS.VALUE]}
                                                            onChange={(event) => this.validateFeedbackSettings(index + startingIndex, event.target.value)}
                                                        />
                                                        : element[SETTINGS.VALUE]
                                                }
                                            </div>
                                            <div className="input-unit">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.units`)}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="img-container">
                                            {
                                                this.state.editingOn === element[SETTINGS.ID]
                                                    ? (
                                                        <div>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => this.onOkClickfeedback(element)}
                                                            >
                                                                <Ok
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    this.props.getfeedbackSettings();
                                                                    this.setState({ editingOn: '' });
                                                                }}
                                                            >
                                                                <Cancel
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                    : (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                this.props.getFeedbackSettingById(
                                                                    element[SETTINGS.ID],
                                                                    (feedbacksettings) => {
                                                                        this.setState({
                                                                            editingOn: element[SETTINGS.ID],
                                                                            feedbacksettings,
                                                                        });
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <Edit
                                                                fillColor={Styles.primaryColor}
                                                            />
                                                        </span>
                                                    )
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        )
                }
            </div>
        );
    }


    renderEventSettings = (list, startingIndex = 0) => {
        const { SETTINGS } = PARSING_KEYS;
        return (
            <div className="other-settings-panel">
                {
                    (list.length === 0)
                        ? (
                            <div className="text-center">
                                {this.props.i18n.t('common.noDataAvailable')}
                            </div>
                        )
                        : (
                            list.map((element, index) => {
                                return (
                                    <div key={element[SETTINGS.ID]} className="settings-element">
                                        <div className="input-label">
                                            <div>
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.text`)}
                                            </div>
                                            <div className="hint-text">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.hint-text`)}
                                            </div>
                                        
                                        <div className="input-container">
                                            <div className="input-value">
                                                {
                                                    this.state.editingOn === element[SETTINGS.ID]
                                                        ? <Input
                                                            bsSize="sm"
                                                            value={element[SETTINGS.VALUE]}
                                                            onChange={(event) => this.validateEventSettings(index + startingIndex, event.target.value)}
                                                        />
                                                        : element[SETTINGS.VALUE]
                                                }
                                            </div>
                                            <div className="input-unit">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.units`)}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="img-container">
                                            {
                                                this.state.editingOn === element[SETTINGS.ID]
                                                    ? (
                                                        <div>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => this.onOkClickEvent(element)}
                                                            >
                                                                <Ok
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    this.props.geteventSettings();
                                                                    this.setState({ editingOn: '' });
                                                                }}
                                                            >
                                                                <Cancel
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                    : (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                this.props.getEventSettingById(
                                                                    element[SETTINGS.ID],
                                                                    (eventsettings) => {
                                                                        this.setState({
                                                                            editingOn: element[SETTINGS.ID],
                                                                            eventsettings,
                                                                        });
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <Edit
                                                                fillColor={Styles.primaryColor}
                                                            />
                                                        </span>
                                                    )
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        )
                }
            </div>
        );
    }

    renderDataUpdate = (list, startingIndex = 0) => {
        const { SETTINGS } = PARSING_KEYS;
        return (
            <div className="other-settings-panel">
                {
                    (list.length === 0)
                        ? (
                            <div className="text-center">
                                {this.props.i18n.t('common.noDataAvailable')}
                            </div>
                        )
                        : (
                            list.map((element, index) => {
                                return (
                                    <div key={element[SETTINGS.ID]} className="settings-element">
                                        <div className="input-label">
                                            <div>
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.text`)}
                                            </div>
                                            <div className="hint-text">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.hint-text`)}
                                            </div>
                                       
                                        <div className="input-container">
                                            <div className="input-value">
                                                {
                                                    this.state.editingOn === element[SETTINGS.ID]
                                                        ? <Input
                                                            bsSize="sm"
                                                            value={element[SETTINGS.VALUE]}
                                                            onChange={(event) => this.validateDataUpdate(index + startingIndex, event.target.value)}
                                                        />
                                                        : element[SETTINGS.VALUE]
                                                }
                                            </div>
                                            <div className="input-unit">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.units`)}
                                            </div>
                                        </div>
                                        </div>
                                        <div className="img-container">
                                            {
                                                this.state.editingOn === element[SETTINGS.ID]
                                                    ? (
                                                        <div>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => this.onOkClickDataUpdate(element)}
                                                            >
                                                                <Ok
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    this.props.getdataUpdate();
                                                                    this.setState({ editingOn: '' });
                                                                }}
                                                            >
                                                                <Cancel
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                    : (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                this.props.getDataUpdateById(
                                                                    element[SETTINGS.ID],
                                                                    (dataupdate) => {
                                                                        this.setState({
                                                                            editingOn: element[SETTINGS.ID],
                                                                            dataupdate,
                                                                        });
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <Edit
                                                                fillColor={Styles.primaryColor}
                                                            />
                                                        </span>
                                                    )
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        )
                }
            </div>
        );
    }

    renderReportLostItem = (list, startingIndex = 0) => {
        const { SETTINGS } = PARSING_KEYS;
        return (
            <div className="other-settings-panel">
                {
                    (list.length === 0)
                        ? (
                            <div className="text-center">
                                {this.props.i18n.t('common.noDataAvailable')}
                            </div>
                        )
                        : (
                            list.map((element, index) => {
                                return (
                                    <div key={element[SETTINGS.ID]} className="settings-element">
                                        <div className="input-label">
                                            <div>
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.text`)}
                                            </div>
                                            <div className="hint-text">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.hint-text`)}
                                            </div>
                                        
                                        <div className="input-container">
                                            <div className="input-value">
                                                {
                                                    this.state.editingOn === element[SETTINGS.ID]
                                                        ? <Input
                                                            bsSize="sm"
                                                            value={element[SETTINGS.VALUE]}
                                                            onChange={(event) => this.validateReportLostItem(index + startingIndex, event.target.value)}
                                                        />
                                                        : element[SETTINGS.VALUE]
                                                }
                                            </div>
                                    
                                            <div className="input-unit">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.units`)}
                                            </div>
                                        </div>
                                    </div>
                                        <div className="img-container">
                                            {
                                                this.state.editingOn === element[SETTINGS.ID]
                                                    ? (
                                                        <div>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => this.onOkClickReportLostItem(element)}
                                                            >
                                                                <Ok
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    this.props.getreportlostItem();
                                                                    this.setState({ editingOn: '' });
                                                                }}
                                                            >
                                                                <Cancel
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                    : (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                this.props.getReportLostItemById(
                                                                    element[SETTINGS.ID],
                                                                    (reportlostitem) => {
                                                                        this.setState({
                                                                            editingOn: element[SETTINGS.ID],
                                                                            reportlostitem,
                                                                        });
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <Edit
                                                                fillColor={Styles.primaryColor}
                                                            />
                                                        </span>
                                                    )
                                            }
                                        </div>

                                    </div>
                                )
                            })
                        )
                }
            </div>
        );
    }

    renderSecurityService = (list, startingIndex = 0) => {
        const { SETTINGS } = PARSING_KEYS;
        return (
            <div className="other-settings-panel">
                {
                    (list.length === 0)
                        ? (
                            <div className="text-center">
                                {this.props.i18n.t('common.noDataAvailable')}
                            </div>
                        )
                        : (
                            list.map((element, index) => {
                                return (
                                    <div key={element[SETTINGS.ID]} className="settings-element">
                                        <div className="input-label">
                                            <div>
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.text`)}
                                            </div>
                                            <div className="hint-text">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.hint-text`)}
                                            </div>
                                        
                                        <div className="input-container">
                                            <div className="input-value">
                                                {
                                                    this.state.editingOn === element[SETTINGS.ID]
                                                        ? <Input
                                                            bsSize="sm"
                                                            value={element[SETTINGS.VALUE]}
                                                            onChange={(event) => this.validateServiceSecurity(index + startingIndex, event.target.value)}
                                                        />
                                                        : element[SETTINGS.VALUE]
                                                }
                                            </div>
                                    
                                            <div className="input-unit">
                                                {this.props.i18n.t(`settings.${element[SETTINGS.ID]}.units`)}
                                            </div>
                                        </div>
                                    </div>
                                        <div className="img-container">
                                            {
                                                this.state.editingOn === element[SETTINGS.ID]
                                                    ? (
                                                        <div>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => this.onOkClickSecurityServices(element)}
                                                            >
                                                                <Ok
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                            <span
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    this.props.getSecurityServices();
                                                                    this.setState({ editingOn: '' });
                                                                }}
                                                            >
                                                                <Cancel
                                                                    fillColor={Styles.primaryColor}
                                                                />
                                                            </span>
                                                        </div>
                                                    )
                                                    : (
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                this.props.getSecurityServicesById(
                                                                    element[SETTINGS.ID],
                                                                    (securityServices) => {
                                                                        this.setState({
                                                                            editingOn: element[SETTINGS.ID],
                                                                            securityServices,
                                                                        });
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <Edit
                                                                fillColor={Styles.primaryColor}
                                                            />
                                                        </span>
                                                    )
                                            }
                                        </div>

                                    </div>
                                )
                            })
                        )
                }
            </div>
        );
    }



    render() {
        return (
            <div className="container settings">
                <Row className="title">
                    <Col sm={12}>
                        <span>{this.props.i18n.t('settings.feedbacksettings')}</span>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col sm={6}>
                        {this.renderFeedbackSettings(this.state.feedbacksettings ? this.state.feedbacksettings.slice(0, parseInt((this.state.feedbacksettings.length + 1) / 2)) : null)}
                    </Col>
                    <Col sm={6}>
                        {this.renderFeedbackSettings(this.state.feedbacksettings ? this.state.feedbacksettings.slice(parseInt((this.state.feedbacksettings.length + 1) / 2)) : null, parseInt((this.state.feedbacksettings.length + 1) / 2))}
                    </Col>
                </Row>
                <Row className="title">
                    <Col sm={12}>
                        <span>{this.props.i18n.t('settings.eventsettings')}</span>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col sm={6}>
                        {this.renderEventSettings(this.state.eventsettings ? this.state.eventsettings.slice(0, parseInt((this.state.eventsettings.length + 1) / 2)) : null)}
                    </Col>
                    <Col sm={6}>
                        {this.renderEventSettings(this.state.eventsettings ? this.state.eventsettings.slice(parseInt((this.state.eventsettings.length + 1) / 2)) : null, parseInt((this.state.eventsettings.length + 1) / 2))}
                    </Col>
                </Row>
                <Row className="title">
                    <Col sm={12}>
                        <span>{this.props.i18n.t('settings.dataupdate')}</span>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col sm={6}>
                        {this.renderDataUpdate(this.state.dataupdate ? this.state.dataupdate.slice(0, parseInt((this.state.dataupdate.length + 1) / 2)) : null)}
                    </Col>
                    <Col sm={6}>
                        {this.renderDataUpdate(this.state.dataupdate ? this.state.dataupdate.slice(parseInt((this.state.dataupdate.length + 1) / 2)) : null, parseInt((this.state.dataupdate.length + 1) / 2))}
                    </Col>
                </Row>
                <Row className="title">
                    <Col sm={12}>
                        <span>{this.props.i18n.t('settings.reportlostitem')}</span>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col sm={6}>
                        {this.renderReportLostItem(this.state.reportlostitem ? this.state.reportlostitem.slice(0, parseInt((this.state.reportlostitem.length + 1) / 2)) : null)}
                    </Col>
                    <Col sm={6}>
                        {this.renderReportLostItem(this.state.reportlostitem ? this.state.reportlostitem.slice(parseInt((this.state.reportlostitem.length + 1) / 2)) : null, parseInt((this.state.reportlostitem.length + 1) / 2))}
                    </Col>
                </Row>
                <Row className="title">
                    <Col sm={12}>
                        <span>{this.props.i18n.t('settings.securityService')}</span>
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col sm={6}>
                        {this.renderSecurityService(this.state.securityServices ? this.state.securityServices.slice(0, parseInt((this.state.securityServices.length + 1) / 2)) : null)}
                    </Col>
                    <Col sm={6}>
                        {this.renderSecurityService(this.state.securityServices ? this.state.securityServices.slice(parseInt((this.state.securityServices.length + 1) / 2)) : null, parseInt((this.state.securityServices.length + 1) / 2))}
                    </Col>
                </Row>
            </div >
        );
    }
}

export default withNamespaces()(Settings);
