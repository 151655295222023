export const ID = {
    CAMPUS: '<campusId>',
    BUILDING: '<buildingId>',
    FLOOR: '<floorId>',
    SETTINGS: '<settingsId>',
    BANNERS: '<bannersId>',
}

export const URLS = {
    BASE_URL: '/appgw/admin/',
    FORGOT_PASSWORD: '/v1/forgot-password',
    LOGIN: '/login',
    LOGOUT: 'logout',
    CAMPUSES: '/v1/campuses/',
    BUILDINGS_FLOORS_LOCATIONS: `/campuses/${ID.CAMPUS}/v1/locations`,
    FLOORS_LOCATIONS: `/campuses/${ID.CAMPUS}/buildings/${ID.BUILDING}/v1/locations`,
    BUILDINGS: `/campuses/${ID.CAMPUS}/v1/buildings/`,
    FLOORS: `/campuses/${ID.CAMPUS}/buildings/${ID.BUILDING}/v1/floors/`,
    LOCATIONS: `/campuses/${ID.CAMPUS}/buildings/${ID.BUILDING}/floors/${ID.FLOOR}/v1/locations/`,
    COLLEGES_AND_DEPARTMENTS: '/v1/colleges-and-departments/',
    COMMON_LINKS: '/v1/common-links/',
    SETTINGS: '/v1/settings',
    CONFIDS: '?confIds=',
    GET_ZONES:"/v1/zones/",
    GET_APPROVALS:"/v1/approvals/",
    GET_EMPLOYEE:"/v1/employee/"

}