import React from 'react';
import { Spinner } from 'reactstrap';
import Header from './Header';
// hoc
import { URLS } from '../../HOC/Communication/ApiUrls';
import { withModal } from '../../HOC/Modal';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { axiosInstance } from '../../HOC/Communication';
// storage
import Storage from '../../Storage';

/**
 * Header Container 
 */

class HeaderContainer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            userDisplayName: '',
            initials: '',
        }
    }

    /**
     * init axios
     */
    init = () => {
        this.axios = axiosInstance;
        const auth = Storage.get(Storage.keys.AUTH);
        const userDisplayName = (auth) ? auth.userDisplayName : '';
        if (userDisplayName) {
            const lastIndex = userDisplayName.lastIndexOf(' ');
            let initials;
            if (lastIndex) {
                initials = (userDisplayName[0] + userDisplayName[lastIndex + 1]).toUpperCase();
            }
            else {
                initials = (userDisplayName[0] + userDisplayName[1]).toUpperCase();
            }
            this.setState({
                userDisplayName,
                initials,
            })
        }
        else {
            Storage.set(Storage.keys.AUTH, {
                roleId: '',
                userDisplayName: '',
                userId: '',
            });
            this.props.history.push('/login');
        }
    }

    /**
     * logout and push to login
     */
    logout = () => {
        this.props.showModal(
            <Spinner />
        );
        this.axios.post(URLS.LOGOUT)
            .then(res => {
                this.props.closeModal();
                if (!res.isError) {
                    Storage.set(Storage.keys.AUTH, {
                        roleId: '',
                        userDisplayName: '',
                        userId: '',
                    });
                    this.props.history.push('/login');
                }
            })
    }

    componentDidMount() {
        this.init();
    }

    render() {
        return (
            <Header
                logout={this.logout}
                userDisplayName={this.state.userDisplayName}
                initials={this.state.initials}
            />
        );
    }
}

export default compose(
    withModal,
    withRouter,
)(HeaderContainer);